export default {
  path: '/',
  component: () => import(/* webpackChunkName: "auth" */ '@/layouts/auth-layout.vue'),
  children: [
    {
      path: '/login',
      name: 'login',
      meta: { title: 'Login' },
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/index-page.vue'),
    },
    // {
    //   path: '/register',
    //   redirect: { name: 'register' },
    //   children: [
    //     {
    //       path: '',
    //       redirect: { name: 'login' },
    //     },
    //     {
    //       path: ':token',
    //       name: 'register',
    //       meta: { title: 'Register' },
    //       component: () => import('@/views/account/register/index-page.vue'),
    //     },
    //   ],
    // },
    {
      path: '/password/forgot',
      name: 'forgot-password',
      meta: { title: 'Lupa Password' },
      component: () =>
        import(/* webpackChunkName: "auth-forgot" */ '@/views/auth/forgot-password.vue'),
    },
    // {
    //   path: '/reset-password/:token',
    //   name: 'ResetPassword',
    //   meta: { title: 'Rain Admin - Reset Password' },
    //   component: () => import(/* webpackChunkName: "auth-reset" */ '@/views/Auth/PasswordReset.vue'),
    // },
  ],
}
