export default [
  {
    path: '/review',
    children: [
      {
        path: ':token',
        name: 'review-form',
        meta: {title: 'Review Hasil Kerja'},
        component: () => import('@/views/review/form-page.vue'),
      },
      {
        path: ':token/sent',
        name: 'review-finished',
        meta: {title: 'Review Hasil Kerja'},
        component: () => import('@/views/review/finished-page.vue'),
      },
    ],
  },
]