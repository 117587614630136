export default [
  {
    path: '/my-task',
    name: 'my-task',
    meta: { title: 'Tugas Saya', hasFilter: true },
    component: () => import('@/views/my-task/index-page.vue'),
  },
  {
    path: '/task-waiting-qc',
    name: 'task-waiting-qc',
    meta: { title: 'Menunggu QC', hasFilter: true },
    component: () => import('@/views/task-waiting-qc/index-page.vue'),
  },
  {
    path: '/task-by-me',
    name: 'task-by-me',
    meta: { title: 'Tugas Yang Saya Buat', hasFilter: true },
    component: () => import('@/views/task-by-me/index-page.vue'),
  },
  {
    path: '/notification',
    name: 'notification',
    meta: { title: 'Notifikasi' },
    component: () => import('@/views/notification/index-page.vue'),
  },
  {
    path: '/review-score',
    children: [
      {
        path: '',
        name: 'review-index',
        meta: {title: 'Daftar Review', hasFilter: true},
        component: () => import('@/views/review/index-page/main-page.vue'),
      },
      {
        path: ':project_id',
        name: 'review-view',
        meta: {title: 'Rincian Review Proyek', hasFilter: true, backButton: true},
        component: () => import('@/views/review/view-page/main-page.vue'),
      },
      {
        path: ':project_id/update',
        name: 'review-update',
        meta: {title: 'Update Review Proyek', backButton: true},
        component: () => import('@/views/review/update-page/main-page.vue'),
      }
    ]
  },
]