import { createRouter, createWebHistory } from 'vue-router'
import authRoutes from './auth'
import printRoutes from './print'
import dashboard from './dashboard'
import bussiness from './bussiness'
import internalManagement from './internal-management'
import dataMaster from './dataMaster'
import setting from './setting'
import reviewer from './reviewer'

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/index-layout.vue'),
    children: [
      ...reviewer,
      {
        path: '/',
        component: () => import('@/layouts/main-layout.vue'),
        children: [
          {
            path: '/',
            name: 'dashboard',
            meta: { title: 'Dashboard' },
            component: () =>
              import('@/views/dashboard/index-page.vue'),
          },
          {
            path: '/change-log',
            name: 'change-log',
            meta: { title: 'Riwayat Perubahan' },
            component: () => import('@/views/change-log/index-page.vue'),
          },
          {
            path: '/forbidden',
            name: '403',
            meta: { title: 'Tidak Dapat Diakses' },
            component: () => import('../views/error/error-403.vue'),
          },
          {
            path: '/:pathMatch(.*)*',
            name: '404',
            meta: { title: 'Halaman Tidak Ditemukan' },
            component: () => import('../views/error/error-404.vue'),
          },
          {
            path: '/maintenance',
            name: '503',
            meta: { title: 'Dalam Perbaikan' },
            component: () => import('../views/error/error-503.vue'),
          },
          {
            path: '/invoice',
            children: [
              {
                path: '',
                name: 'invoice-index',
                meta: { title: 'Tagihan Pending' },
                component: () => import('@/views/invoice/index-page.vue'),
              },
            ],
          },
          ...dashboard,
          ...bussiness,
          ...internalManagement,
          ...dataMaster,
          ...setting,
        ],
      },
      authRoutes,
      printRoutes,
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = String(nearestWithTitle.meta.title) + ' - ' + import.meta.env.VITE_PROJECT_NAME
  } else if (previousNearestWithMeta) {
    document.title = String(previousNearestWithMeta.meta.title) + ' - ' + import.meta.env.VITE_PROJECT_NAME
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode?.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  const arrayTags = <{[key:string]: string}[]>nearestWithMeta.meta.metaTags
  arrayTags.map((tagDef) => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
  // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
